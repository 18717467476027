import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  photoURL: '',
  isSignIn: false,
  email:'',
  displayName:'',
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  photoURL (state,
    photoURL
  ) {
    state['photoURL'] = photoURL;
  },
  isSignIn (state,
    isSignIn
  ) {
    state['isSignIn'] = isSignIn;
  },
  email (state,
    email
  ) {
    state['email'] = email;
  },
  displayName (state,
    displayName
  ) {
    state['displayName'] = displayName;
  },
}
const getters = {
  photoURL:(state) => {
    return state.photoURL
  },
  isSignIn:(state) => {
    return state.isSignIn
  },
  email: (state) => {
    return state.email
  },
  displayName: (state) => {
    return state.displayName
  }
}
export default new Vuex.Store({
  state,
  mutations,
  getters
})