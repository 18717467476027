import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views

// Views - Components
const Host = () => import('@/views/host/Host')
const Class = () => import('@/views/class/Class')
const Meeting = () => import('@/views/meeting/Meeting')
const MeetingReport1 = () => import('@/views/meeting/Report1')
const Upload = () => import('@/views/upload/Upload')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/host',
      name: '首頁',
      component: TheContainer,
      children: [
        {
          path: 'host',
          name: '班長資料',
          component: Host
        },
        {
          path: 'class',
          name: '班級資料',
          component: Class
        },
        {
          path: 'meeting',
          name: '會議',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: '列表',
              component: Meeting
            },
            {
              path: 'report1',
              name: '報表',
              component: MeetingReport1
            },
          ]
        },
        {
          path: 'upload',
          name: '資料上傳',
          component: Upload
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },

      
        
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

