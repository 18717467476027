import 'core-js/stable'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-TW'
import XLSX from 'xlsx'

router.beforeEach((to, from, next) => {
  if(store.state.isSignIn || to.name === "Login"){
      next();
    }else{
      next('/pages/login');
    }
 })

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(ElementUI, { locale })
Vue.use(Vuex)
Vue.use(XLSX)
Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
