<template>
  <router-view></router-view>
</template>

<script>

import { initializeApp } from 'firebase/app';
 // Your web app's Firebase configuration
 var firebaseConfig = {
    apiKey: "AIzaSyDWV_eGxjjPNgSusdchg89oivRnaAVIIXY",
    authDomain: "zoom-69c15.firebaseapp.com",
    databaseURL: "https://zoom-69c15.firebaseio.com",
    projectId: "zoom-69c15",
    storageBucket: "zoom-69c15.appspot.com",
    messagingSenderId: "138231267595",
    appId: "1:138231267595:web:029aadfd0edb3de99ce20e"
  };
  // Initialize Firebase
  initializeApp(firebaseConfig)

export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
